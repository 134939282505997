import React, { useEffect } from "react";
import Layout from "../../components/_App/layout";
import SEO from "../../components/_App/seo";
import Navbar from "../../components/_App/NavbarStyleFour";
import Footer from "../../components/_App/Footer";
import PageBanner from "../../components/Common/PageBanner";
import * as Icon from "react-feather";
import { Link } from "gatsby";
import BlogSidebar from "../../components/Blog/BlogSidebar";
import virtual_lesson from "../../assets/images/blog-image/virtual_lesson.jpg";
import vr from "../../assets/images/blog-image/vr.jpg";
import { CodeBlock, vs2015 } from "react-code-blocks";
import Blog from "../../assets/json/blog.json";

function MyCoolCodeBlock({ code, language, showLineNumbers }) {
    return (
        <CodeBlock
            text={code}
            language={language}
            showLineNumbers={showLineNumbers}
            theme={vs2015}
        />
    );
}

const articleData = {
    id: 8,
    title: "Libreria Redux per la gestione avanzata dello stato nell’App",
    desc:
        "Redux è una libreria di gestione dello stato che è diventata uno standard de facto nell'ecosistema delle applicazioni React.",
    img: "/blog-image/redux.jpg",
    imgs: [
        "/blog-image/react_native.png",
        "/blog-image/app.png",
        "/blog-image/app2.png",
    ],
    page: "blog/redux",
    data: "28/08/2023",
    author: "Staff",
};

const BlogDetails = () => {
    const [isMobile, setMobile] = React.useState(false);

    const handleResize = () => {
        if (window.innerWidth < 1275) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <Layout>
            <SEO
                title={articleData.title}
                meta={[
                    {
                        name: `description`,
                        content:
                            "Nell'ambito dello sviluppo delle applicazioni React, la gestione dello stato è una delle sfide più complesse e critiche.",
                    },
                    {
                        property: `og:title`,
                        content: articleData.title + "Appius, software house",
                    },
                ]}
            />

            <Navbar />

            <PageBanner pageTitle={articleData.title} />

            <div className="blog-details-area ptb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="blog-details-desc">
                                <div className="article-image">
                                    <img
                                        src={
                                            require("../../assets/images" +
                                                articleData.img).default
                                        }
                                        alt="image"
                                    />
                                </div>

                                <div className="article-content">
                                    <div className="entry-meta">
                                        <ul>
                                            <li>
                                                <Icon.Clock />{" "}
                                                {articleData.data}
                                            </li>
                                            <li>
                                                <Icon.User />{" "}
                                                <Link to="#">
                                                    {articleData.author}
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>

                                    <p>
                                        Nell'ambito dello sviluppo delle
                                        applicazioni React, la gestione dello
                                        stato è una delle sfide più complesse e
                                        critiche. Avere un controllo rigoroso
                                        sullo stato dell'applicazione è
                                        fondamentale per garantire la coerenza
                                        dei dati e l'efficienza delle
                                        operazioni. In questo articolo,
                                        esploreremo a fondo la libreria Redux,
                                        analizzandone le principali funzionalità
                                        e le tecniche di programmazione, al fine
                                        di comprendere come Redux si integra in
                                        modo sofisticato con le applicazioni
                                        React per gestire lo stato in modo
                                        prevedibile e scalabile.
                                    </p>
                                    <h3>Introduzione a Redux</h3>
                                    <p>
                                        Redux è una libreria di gestione dello
                                        stato che è diventata uno standard de
                                        facto nell'ecosistema delle applicazioni
                                        React. Ideata da Dan Abramov e Andrew
                                        Clark, Redux si basa sul concetto di
                                        architettura Flux e promuove una
                                        gestione chiara e centralizzata dello
                                        stato nell'applicazione. Una delle sue
                                        caratteristiche principali è la
                                        creazione di un singolo "store" che
                                        contiene lo stato globale
                                        dell'applicazione, rendendo più semplice
                                        la gestione dei dati e il flusso delle
                                        informazioni.
                                    </p>
                                    <h3>Principali Funzionalità</h3>
                                    <p>
                                        Ecco le principali funzionalità di Redux
                                        che ne fanno una scelta potente per la
                                        gestione dello stato in applicazioni
                                        React:
                                    </p>
                                    <h5>1. Store Centrale</h5>
                                    <p>
                                        L'elemento fondamentale di Redux è lo
                                        "store". Questo è un contenitore
                                        immutabile che memorizza lo stato
                                        globale dell'applicazione. Gli stati
                                        sono modificabili solo tramite "azioni"
                                        speciali e attraverso funzioni pure
                                        chiamate "riduttori". Questo approccio
                                        garantisce un controllo rigoroso sullo
                                        stato, eliminando modifiche accidentali
                                        o effetti collaterali indesiderati.
                                    </p>
                                    <h5>2. Azioni e Riduttori</h5>
                                    <p>
                                        Le "azioni" in Redux rappresentano gli
                                        eventi che descrivono un cambiamento
                                        nello stato. I "riduttori" sono funzioni
                                        pure che prendono lo stato attuale e
                                        un'azione come argomenti e restituiscono
                                        un nuovo stato. Questo flusso
                                        unidirezionale e deterministico delle
                                        azioni attraverso i riduttori assicura
                                        una gestione prevedibile dello stato e
                                        semplifica il debug.
                                    </p>
                                    <h5>3. Middleware</h5>
                                    <p>
                                        Redux offre un'architettura di
                                        middleware flessibile che consente di
                                        estendere e personalizzare il flusso
                                        delle azioni. I middleware possono
                                        essere utilizzati per gestire
                                        asincronicità, registrazioni, analisi e
                                        altro ancora. Un esempio comune di
                                        middleware è "redux-thunk", che consente
                                        di eseguire azioni asincrone in Redux.
                                    </p>
                                    <h5>4. DevTools Redux</h5>
                                    <p>
                                        Uno strumento potente per gli
                                        sviluppatori Redux è l'estensione del
                                        browser "Redux DevTools". Questo
                                        strumento permette di ispezionare lo
                                        stato, le azioni e il flusso dei
                                        riduttori in modo visuale e interattivo.
                                        Semplifica notevolmente il debug e
                                        l'analisi dei problemi relativi allo
                                        stato.
                                    </p>
                                    <h5>5. Integrazione con React</h5>
                                    <p>
                                        Redux è spesso utilizzato in
                                        combinazione con React attraverso la
                                        libreria "react-redux". Questa libreria
                                        semplifica l'integrazione di Redux in
                                        un'app React, fornendo componenti ad hoc
                                        come `Provider` e il hook `useSelector`
                                        per accedere allo stato dal componente.
                                    </p>
                                    <h3>Utilizzo di Redux</h3>
                                    <p>
                                        Ecco un esempio di come utilizzare Redux
                                        all'interno di un'applicazione React:
                                    </p>
                                    <br />
                                    <br />
                                    {isMobile ? (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? <br />
                                                    <span
                                                        style={{ fontSize: 20 }}
                                                    >
                                                        Clicca qui
                                                    </span>
                                                </Link>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? Clicca qui
                                                </Link>
                                            </div>
                                        </>
                                    )}
                                    <br />
                                    <h5>1. Installazione</h5>
                                    <p>
                                        Installazione della libreria principale
                                        Redux e “react-redux”
                                    </p>
                                    <MyCoolCodeBlock
                                        code={`npm install redux react-redux`}
                                        language="javascript"
                                        showLineNumbers={true}
                                    />
                                    <br />
                                    <MyCoolCodeBlock
                                        code={`yarn add redux react-redux`}
                                        language="javascript"
                                        showLineNumbers={true}
                                    />
                                    <h5>2. Configurazione</h5>
                                    <p>
                                        Dopo l'installazione, è necessario
                                        configurare Redux nel progetto. Questo
                                        include la creazione dello store e la
                                        configurazione di eventuali middleware.
                                        Ecco un esempio di configurazione
                                        iniziale:
                                    </p>
                                    <MyCoolCodeBlock
                                        code={`
    import { createStore, applyMiddleware } from 'redux';
    import rootReducer from './reducers'; // Importa i tuoi riduttori
    import thunkMiddleware from 'redux-thunk';

    const store = createStore(rootReducer, applyMiddleware(thunkMiddleware));
                                        `}
                                        language="javascript"
                                        showLineNumbers={true}
                                    />
                                    <p className="pt-3">
                                        Ora puoi fornire lo store agli altri
                                        componenti utilizzando il componente
                                        Provider di react-redux
                                    </p>
                                    <h5>
                                        3. Definizione degli Stati e delle
                                        Azioni
                                    </h5>
                                    <p>
                                        Definizione degli stati iniziali e le
                                        azioni che cambiano lo stato. Le azioni
                                        sono semplici oggetti JavaScript con una
                                        chiave "type" che definisce il tipo di
                                        azione e, se necessario, un payload di
                                        dati. Ad esempio:
                                    </p>
                                    <MyCoolCodeBlock
                                        code={`
    // Definizione di uno stato iniziale
    const initialState = {
        count: 0,
    };

    // Definizione di un'azione
    const incrementAction = {
        type: 'INCREMENT',
        payload: 1,
    };
    `}
                                        language="javascript"
                                        showLineNumbers={true}
                                    />
                                    <h5>4. Implementazione dei Riduttori</h5>
                                    <p>
                                        I riduttori sono funzioni pure che
                                        prendono lo stato attuale e un'azione
                                        come argomenti e restituiscono un nuovo
                                        stato. Ad esempio:
                                    </p>
                                    <MyCoolCodeBlock
                                        code={`
    // Definizione di un riduttore
    const counterReducer = (state = initialState, action) => {
        switch (action.type) {
        case 'INCREMENT':
            return {
            ...state,
            count: state.count + action.payload,
            };
        default:
            return state;
        }
    };
    `}
                                        language="javascript"
                                        showLineNumbers={true}
                                    />
                                    <h5>5. Collegamento a Componenti React</h5>
                                    <p>
                                        Per collegare gli stati e le azioni di
                                        Redux a un componente React, utilizza il
                                        componente `connect` fornito da
                                        `react-redux`. È possibile utilizzare il
                                        hook `useSelector` per accedere allo
                                        stato:
                                    </p>
                                    <MyCoolCodeBlock
                                        code={`
    import React from 'react';
    import { connect, useSelector } from 'react-redux';

    class CounterComponent extends React.Component {
        render() {
            return (
                <div>
                    <p>Count: {this.props.count}</p>
                    <button onClick={this.props.increment}>Increment</button>
                </div>
            );
        }
    }

    const mapStateToProps = (state) => {
        return {
            count: state.count,
        };
    };

    const mapDispatchToProps = (dispatch) => {
        return {
            increment: () => dispatch({ type: 'INCREMENT', payload: 1 }),
        };
    };

    export default connect(mapStateToProps, mapDispatchToProps)(CounterComponent);

    function FunctionalCounterComponent() {
        const count = useSelector(state => state.count);
        
        return (
            <div>
                <p>Count: {count}</p>
                <button onClick={() => dispatch({ type: 'INCREMENT', payload: 1 })}>Increment</button>
            </div>
        );
    }

    `}
                                        language="javascript"
                                        showLineNumbers={true}
                                    />
                                    <p className="mt-3">
                                        Redux è una libreria potente e complessa
                                        per la gestione dello stato in
                                        applicazioni React - offre una gestione
                                        prevedibile e scalabile dello stato, il
                                        che è cruciale per applicazioni
                                        complesse e di grandi dimensioni. Redux
                                        può semplificare notevolmente la
                                        gestione dello stato e migliorare la
                                        manutenibilità del codice.
                                    </p>

                                    <br />
                                    <br />
                                    <br />
                                    {isMobile ? (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? <br />
                                                    <span
                                                        style={{ fontSize: 20 }}
                                                    >
                                                        Clicca qui
                                                    </span>
                                                </Link>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? Clicca qui
                                                </Link>
                                            </div>
                                        </>
                                    )}
                                    <br />
                                </div>

                                <div
                                    style={{ marginTop: 30 }}
                                    className="startp-post-navigation"
                                >
                                    {Blog.articles
                                        .filter((a) => a.id != articleData.id)
                                        .slice(0, 2)
                                        .map((article) => {
                                            return (
                                                <div className="prev-link-wrapper">
                                                    <div className="info-prev-link-wrapper">
                                                        <Link
                                                            to={
                                                                "/" +
                                                                article.page
                                                            }
                                                        >
                                                            <span className="image-prev">
                                                                <img
                                                                    src={
                                                                        require("../../assets/images" +
                                                                            article.img)
                                                                            .default
                                                                    }
                                                                    alt="image"
                                                                />
                                                                <span className="post-nav-title">
                                                                    VAI
                                                                </span>
                                                            </span>

                                                            <span className="prev-link-info-wrapper">
                                                                <span className="prev-title">
                                                                    {
                                                                        article.title
                                                                    }
                                                                </span>
                                                                <span className="meta-wrapper">
                                                                    <span className="date-post">
                                                                        {
                                                                            article.data
                                                                        }
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12">
                            <BlogSidebar id={articleData.id} />
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </Layout>
    );
};
export default BlogDetails;
